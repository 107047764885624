<script lang="ts" setup>
defineProps<{
  class?: string;
}>();
const { imprintUrl, dataProtectionUrl } = useRuntimeConfig().public;
</script>

<template>
  <div :class="cn('flex gap-4', $props.class)">
    <a
      class="text-sm underline decoration-transparent hover:decoration-color-4 underline-offset-4"
      :href="imprintUrl"
      >Impressum</a
    >
    <a
      class="text-sm underline decoration-transparent hover:decoration-color-4 underline-offset-4"
      :href="dataProtectionUrl"
      >Datenschutz</a
    >
  </div>
</template>
